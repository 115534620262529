
// progressbar.js@1.0.0 version is used
// Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
var ProgressBar = require('progressbar.js')

export default {
    data: function() {
        return {
            id: Math.random().toString(36).substring(2, 15), // + Math.random().toString(36).substring(2, 15)
            bar: {}
        }
    },
    props: {
        value: {type: Number, default: 99},
        min_value: {type: Number, default: null},
        max_value: {type: Number, default: 100},
        show_text: {type: Boolean, default: true},
        prefix: {type: String, default: null},
        suffix: {type: String, default: null},
        show_relative: {type: Boolean, default: false},
        width: {type: String, default: '250px'},
        from_color: {type: String, default: '#ff0000'},
        to_color: {type: String, default: '#00a100'},
        font_size: {type: String, default: '1rem'},
        font_color: {type: String, default: '#aaa'},
        trail_color: {type: String, default: '#aaa'},
    },
    methods: {
        renderProgress: function() {
            var from_color = this.from_color
            var to_color = this.to_color

            if (this.min_value !== null) {
                if (this.value >= this.min_value) {
                    from_color = this.to_color
                } else {
                    to_color = this.from_color
                }
            }

            let vm = this

            this.bar = new ProgressBar.Line('#progress-line-' + vm.id, {
                color: vm.font_color,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 5,
                trailWidth: 2,
                trailColor: vm.trail_color,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false,
                    style: {
                        paddingTop: '1rem',
                        fontFamily: '"Raleway", Helvetica, sans-serif',
                        fontSize: vm.font_size,
                    }
                },
                from: { color: from_color, width: 4 },
                to: { color: to_color, width: 5 },
                // Set default step function for all animate calls
                step: function(state, bar) {
                    bar.path.setAttribute('stroke', state.color)
                    bar.path.setAttribute('stroke-width', state.width)

                    if (vm.show_text) {
                        var value
                        if (vm.suffix === null && vm.prefix === null && !vm.show_relative) {
                            value = Math.round(bar.value() * 100) + '%'
                        } else {
                            value = Math.round(bar.value() * vm.max_value)
                            if (vm.prefix != null) value = vm.prefix + ' ' + value
                            if (vm.show_relative) value = value + '/' + vm.max_value
                            if (vm.suffix != null) value = value + ' ' + vm.suffix
                        }
                        bar.setText(value)
                    }
                }
            })

            this.animate()
        },
        animate() {
            if (this.value && this.max_value) {
                if (this.value > this.max_value) {
                    this.bar.animate(1)
                } else {
                    this.bar.animate(this.value / this.max_value)  // Number from 0.0 to 1.0
                }
            }
        },
        reInitializeProgress() {
            this.bar.destroy()
            this.renderProgress()
        }
    },
    mounted() {
        this.renderProgress()
    },
    watch: {
        value: function(newValue, oldValue) {
            if (
                (newValue < this.min_value && oldValue >= this.min_value) ||
                (newValue >= this.min_value && oldValue < this.min_value)
            ) return this.reInitializeProgress()

            if (newValue < oldValue) this.bar.set(0)
            this.animate()
        },
        max_value: function() {
            this.reInitializeProgress()
        },
    },
    computed: {
        cssCustom () {
            return {
                '--width': this.width,
            }
        }
    }
}
