
import { mapGetters, mapState } from 'vuex'
import { SUBSCRIBE_REQUEST, UNSUBSCRIBE_REQUEST } from '@/store/actions/subscription.ts'
import { UPDATE_EXCURSION_REQUEST, DELETE_EXCURSION_REQUEST } from '@/store/actions/excursion.ts'
import { SET_POLICY } from '@/store/actions/setting.ts'
import youSure from '@/utils/ConfirmationModal.ts'
import ID from '@/components/account/ID.vue'

export default {
    data: function() {
        return {
            mounted: false,
            pending_subscription: false,
            preference: 1,
        }
    },
    props: [
        'excursion',
        'subscriptions',
        'all_subscriptions',
    ],
    methods: {
        toggleActive() {
            let toggle_state = (this.excursion.active) ? 'inactief' : 'actief'
            youSure(this, 'Weet je zeker dat je de ' + this.excursion.type + ' <b>' + toggle_state + '</b> wilt maken?')
                .then((res) => {
                    if (res) {
                        this.excursion.id = this.excursion._id
                        this.excursion.active = !this.excursion.active
                        this.$store.dispatch(UPDATE_EXCURSION_REQUEST, this.excursion)
                    }
                })
        },
        deleteExcursion() {
            this.$set(this.excursion, 'deleting', true)

            youSure(this, 'Weet je zeker dat je de activiteit wilt verwijderen?<br /><br />' +
                'Hierdoor zullen de gegevens verloren gaan.')
                .then((res) => {
                    if (res) {
                        this.$store.dispatch(DELETE_EXCURSION_REQUEST, this.excursion._id)
                        if (this.$route.params.excursion) {
                            this.$router.push('/activiteiten/' + this.$route.params.scope)
                        }
                    } else this.excursion.deleting = false
                })
        },
        subscribe() {
            if (this.pending_subscription && this.checkPolicy() && this.checkID()) {
                this.$store.dispatch(SUBSCRIBE_REQUEST, {
                    'excursion_id': this.excursion._id, 'preference': this.preference
                })
                this.pending_subscription = false
            }
        },
        subscribe_request: function(preference) {
            this.preference = preference
            this.pending_subscription = true
        },
        unSubscribe() {
            this.$store.dispatch(UNSUBSCRIBE_REQUEST, this.excursion.subscribed)
        },
        checkPolicy() {
            let policy =
                (this.excursion.scope == 'keuzedeel') ? 'keuzedelen_terms' : (this.excursion.type === 'reis') ? 'reis_terms' : 'activity_terms'

            if (!this.$store.state.user.profile['accept_' + policy]) {
                this.$store.commit(SET_POLICY, policy)
                this.$bvModal.show('PolicyPopup-modal')
                return false
            }
            return true
        },
        checkID() {
            if (this.excursion.type === 'reis') {
                if (!ID.methods.validate(this)) {
                    this.$bvModal.show('IDPopUp-modal')
                    return false
                }
                return true
            }
            return true
        },
        availablePreferences() {
            let amount_of_preferences = Number(this.settings.keuzedelen_subscription_preferences)
            let preferences = Array.apply(null, Array(amount_of_preferences)).map((_, i) => i+1)
            let used_preferences = this.getMySubscriptions
                .filter(sub => sub.scope === 'keuzedeel')
                .map(sub => sub.preference)

            let available_preferences = preferences
                .filter(pref => used_preferences.indexOf(pref) == -1)

            return available_preferences
        }
    },
    computed: {
        isKeuzedeel: function() {
            return this.excursion.type === 'keuzedeel'
        },
        ...mapState({
            profile: (state: any) => state.user.profile,
            settings: (state: any) => state.setting.settings,
            excursion_status: (state: any) => state.excursion.status
        }),
        ...mapGetters([
            'isAdmin', 'isTeacher', 'isStudent',
            'getMyHours',
            'getMySubscriptions'
        ]),
        updateSubscription() {
            return this.pending_subscription, this.$store.state.user.profile, Date.now()
        },
    },
    mounted() {
        this.mounted = true
    },
    watch: {
        updateSubscription() {
            this.subscribe()
        }
    }
}
